import React, { useMemo, useCallback, useState } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { graphql } from 'gatsby';
import { Button, Image } from 'react-bootstrap';

import { UqPage, UqHeroV2, UqLayoutV2, UqHero, UqSearchBar, UqContainer, UqRequestDemoBanner, UqTextHighlight, UqSvg, UqToggle, UqFilterBar, UqAppBarV2, UqNavbarV2, UqFooterV2 } from '@uq-components';
import { sortIntegrationsBy, SortBy } from '@uq-helpers';
import { useSearch, useQueryParams } from '@uq-hooks';
import { heroShape, integrationShape, seoShape } from '../../models';

import * as styles from './index.module.scss';

const filterOptions = [
  { key: 'all', label: 'All' },
  { key: 'app-store', label: 'App Store' },
  { key: 'social-and-community', label: 'Social and Community' },
  { key: 'customer-support', label: 'Customer Support' },
  { key: 'data-upload-and-enrichment', label: 'Data Upload and Enrichment' },
  { key: 'development-and-monitoring', label: 'Development and Monitoring' },
  { key: 'productivity', label: 'Productivity' },
  { key: 'review-sites', label: 'Review Sites' },
  { key: 'surveys', label: 'Surveys' },
];

const toggleOptions = [
  { label: 'Popular', value: SortBy.POPULARITY },
  { label: 'A-Z', value: SortBy.ALPHABETICAL },
];

const integrationsEntityShape = PropTypes.shape({
  edges: PropTypes.arrayOf(
    PropTypes.shape({
      node: PropTypes.shape({
        content: PropTypes.arrayOf(integrationShape),
      }),
    }),
  ),
});

UqIntegrationsPage.propTypes = {
  data: PropTypes.shape({
    featured: integrationsEntityShape,
    hero: heroShape,
    integrations: integrationsEntityShape,
    seo: seoShape,
  }),
};

export default function UqIntegrationsPage(props) {
  const featured = props.data.featured.edges[0].node.content;
  const hero = props.data.hero.edges[0].node;
  const seo = props.data.seo.edges[0].node;

  const { params, updateParameter } = useQueryParams(['filter']);
  const selectedFilter = params['filter'];

  const [sort, setSort] = useState(SortBy.POPULARITY);

  const integrations = useMemo(() => {
    const items = props.data.integrations.edges.map(v => v.node);

    sortIntegrationsBy(items, sort);

    if (selectedFilter && selectedFilter !== 'all') {
      return items.filter(v => v.category === selectedFilter);
    }

    return items;
  }, [props.data.integrations, sort, selectedFilter]);

  const { executeQuery, results } = useSearch(integrations, ['name']);

  const handleInput = useCallback((v) => executeQuery(v), [executeQuery]);

  return (
    <UqPage config={{ seo }}>
      <UqLayoutV2>
        <UqAppBarV2>
          <UqNavbarV2 />
        </UqAppBarV2>

        <UqHeroV2>
          <UqContainer>
            <UqHero
              image={(
                <Image src={hero.media.url} />
              )}
              title={hero.title}
              subtitle={hero.subtitle}
            />
          </UqContainer>
        </UqHeroV2>
        <UqContainer fullWidth>
          <section className={styles.gridContainer}>
            <UqContainer className={styles.section}>
              <h2 className={styles.title}>
                <UqTextHighlight>
                  **Featured** unitQ Integrations
                </UqTextHighlight>
              </h2>
              <section className={styles.integrations}>
                {featured.map(renderIntegrationTile)}
              </section>
            </UqContainer>
            <UqContainer className={styles.section}>
              <h2 className={styles.title}>
                All unitQ Integrations
              </h2>
              <section className={styles.searchBar}>
                <UqSearchBar
                  onChange={handleInput}
                  placeholder="Search Integrations"
                  info={results && !results.length
                    ? 'There were no results for the search above.'
                    : undefined
                  }
                  variant="rounded"
                />
                <section className={styles.toggleContainer}>
                  <span>Sort</span>
                  <UqToggle
                    name="sort-toggle"
                    onChange={(e) => setSort(e)}
                    options={toggleOptions}
                    value={sort}
                  />
                </section>
              </section>
            </UqContainer>
            <UqContainer fullWidth>
              <section className={styles.filterBarContainer}>
                <section className={styles.filterBar}>
                  <UqFilterBar
                    options={filterOptions}
                    onOptionSelect={(key) => updateParameter('filter', key)}
                    value={selectedFilter || 'all'}
                  />
                </section>
              </section>
            </UqContainer>
            <UqContainer>
              <section className={styles.integrations}>
                {(results || integrations).map(renderIntegrationTile)}
              </section>
            </UqContainer>
          </section>
        </UqContainer>
        <UqContainer>
          <UqRequestDemoBanner />
        </UqContainer>

        <UqFooterV2 />
      </UqLayoutV2>
    </UqPage>
  );

  function renderIntegrationTile(item, index) {
    return (
      <section
        key={index}
        className={styles.integrationTile}
      >
        <UqSvg>{item.logo?.svg.content}</UqSvg>
        {
          item.url
            ? (
              <Button href={item.url}>
                Learn More
              </Button>
            )
            : (
              <span className={classNames('body-2', styles.name)}>
                {item.name}
              </span>
            )
        }
      </section>
    );
  }
}

export const pageQuery = graphql`
  query {
    featured: allContentfulContentGroup(filter: {slug: {eq: "featured-integrations"}}) {
      edges {
        node {
          content {
            ... on ContentfulIntegration {
              logo {
                svg {
                  content: originalContent
                }
              }
              name
              url
            }
          }
        }
      }
    }
    hero: allContentfulHero(filter: {pageId: {eq: "integrations"}}) {
      edges {
        node {
          media {
            url
          }
          subtitle
          title
        }
      }
    }
    integrations: allContentfulIntegration {
      edges {
        node {
          category
          logo {
            svg {
              content: originalContent
            }
          }
          name
          popularityScore
          url
        }
      }
    }
    seo: allContentfulSeo(filter: {pageId: {eq: "integrations"}}) {
      edges {
        node {
          description
          image {
            url
          }
          title
        }
      }
    }
  }
`;
